import TrimCentralWrapper from "@jordibosch20/software_tools_package/dist/pages/TrimCentralWrapper.component.js"
import styles from "./App.module.css";
import { getStringTrimmed } from "@jordibosch20/software_tools_package/dist/utils/trim.js";
import { useAnalytics } from './useAnalytics';

function App() {
  useAnalytics();
  return (
    <div className={styles.AppWrapper}>
      <div className={styles.App}>
        <TrimCentralWrapper title={"Trim Text"} f={getStringTrimmed}></TrimCentralWrapper>
      </div>
      <div className={styles.text}>
        <p><strong>Introduction</strong>: In the world of content creation and data management, precision is key. Whether you're refining content, coding, or managing data sets, our Precision Text Trimmer is the tool you've been searching for. Offering unparalleled ease and flexibility, it allows you to trim text from the left, right, or both sides with just a few clicks. Ideal for professionals who value efficiency and accuracy, this tool ensures your text is perfectly formatted and ready for publication, development, or analysis.</p>
        <p><strong>Why Our Precision Text Trimmer Stands Out</strong>:</p>
        <ul>
        <li><strong>Versatile Trimming Options</strong>: Choose to trim text from the left, right, or both sides to meet your specific formatting needs.</li>
        <li><strong>User-Friendly Interface</strong>: With a simple and intuitive design, our tool is accessible to users of all skill levels, ensuring a smooth and efficient trimming process.</li>
        <li><strong>Instant Results</strong>: Achieve quick and accurate trimming results, enhancing your productivity and allowing you to focus on the finer details of your work.</li>
        <li><strong>Optimized Workflow</strong>: Streamline your content editing, development, and data management tasks, eliminating unnecessary steps and saving time.</li>
        <li><strong>High Compatibility</strong>: Perfect for a wide range of applications, from web development and programming to content creation and document editing.</li>
        </ul>
        <p><strong>How It Works</strong>:</p>
        <ol>
        <li><strong>Input Your Text</strong>: Paste the text you need to trim into the designated field.</li>
        <li><strong>Select Your Trimming Preference</strong>: Choose whether to trim from the left, right, or both sides, depending on your requirements.</li>
        <li><strong>Trim with One Click</strong>: Execute the trimming action and instantly get your perfectly formatted text, ready for use.</li>
        </ol>
        <p><strong>Applications and Benefits</strong>:</p>
        <p>Our Precision Text Trimmer is a versatile tool that caters to a wide audience, including:</p>
        <ul>
        <li><strong>Web Developers and Programmers</strong>: Clean up code and configuration files by removing unnecessary whitespace or characters.</li>
        <li><strong>Editors and Content Creators</strong>: Refine articles, blogs, and manuscripts by trimming text for better alignment and presentation.</li>
        <li><strong>Data Analysts</strong>: Prepare and clean data sets by trimming spaces or extraneous characters from data entries.</li>
        </ul>
        <div className={styles.links}>
          <h3>Other similar resoures that could be helpful</h3>

          <li><a href="https://check-json.com">JSON format checker</a></li>
          <li><a href="https://check-xml.com">XML Checker</a></li>
          <li><a href="https://check-yaml.com">YAML Checker</a></li>
          <li><a href="https://formatter-json.com">JSON formatter</a></li>
          <li><a href="https://add-delimiters.com">Add delimiters to sentences</a></li>
          <li><a href="https://convert-base.com">Convert base</a></li>
          <li><a href="https://convert-binary.com">Convert text/file to binary</a></li>
          <li><a href="https://convert-hexadecimal.com">Convert text/file to hexadecimal</a></li>
          <li><a href="https://convert-base64.com">Convert text/file to base64</a></li>
          <li><a href="https://css-beautify.com">CSS beautifier</a></li>
          <li><a href="https://html-beautify.com">HTML beautifier</a></li>
          <li><a href="https://javascript-beautify.com">Javascript beautifier</a></li>
          <li><a href="https://xml-beautify.com">XML beautifier</a></li>
          <li><a href="https://extract-email.com">Email extractor</a></li>
          <li><a href="https://extract-phone.com">Phone extractor</a></li>
          <li><a href="https://extract-url.com">URL extractor</a></li>
          <li><a href="https://json-2-csv.com">JSON to CSV online converter</a></li>
          <li><a href="https://json-to-yaml.com">JSON to YAML online converter</a></li>
          <li><a href="https://software-kit.com">Software tools</a></li>
          <li><a href="https://svg2css.com">SVG to CSS background </a></li>
          <li><a href="https://trim-text.com">Text trimmer</a></li>
          <li><a href="https://url-coder.com">URL encoder/decoder</a></li>
          <li><a href="https://yaml2json.com">YAML to JSON</a></li>
          <li><a href="https://csv-to-json.com">CSV to JSON</a></li>
          <li><a href="https://sort-text.com">Sort text</a></li>
          <li><a href="https://convert-casing.com">Convert casing</a></li>
          <br></br>
          <br></br>
          <br></br>
          <span>In case you have any <b>suggerence or found any bug</b> in our tools, please, feel free to email us at statisticskingdom @ gmail.com (without the spaces) and we will take care of it.</span>
        </div>
      </div>
    </div>
  );
}

export default App;